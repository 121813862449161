import moment from 'moment'

export const getDefaultDate = (date) => {
  const defaultDate = moment(date)
  if (defaultDate.day() === 1) defaultDate.subtract(3, 'days')
  else defaultDate.subtract(1, 'days')
  return defaultDate
}

export const getBBDateEstimate = (date) => {
  let bbDate = moment(date).add(1, 'days')
  if (date.day() === 5) bbDate = moment(date).add(3, 'days')
  return bbDate
}

export const getRefAsNumber = (item) => parseInt(item.ref.match(/\d/g).join(''))

export const sortBySPRef = (a, b) => {
  return getRefAsNumber(a) - getRefAsNumber(b)
}

export const getWmAnswerUrl = (answer) =>
  `https://questions-statements.parliament.uk/written-questions/detail/${answer.resourceId}`
