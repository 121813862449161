import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { useEntryApi } from '../lib/api'
import { getRefAsNumber } from '../lib/utils'
import PageLayout from '../components/shared/PageLayout'
import DateForm from '../components/shared/DateForm'
import DateInfo from '../components/shared/DateInfo'
import Loader from '../components/shared/Loader'
import TableButton from '../components/was/TableButton'
import WrittenAnswerList from '../components/was/WrittenAnswerList'
import ShortWrittenAnswerList from '../components/was/ShortWrittenAnswerList'
import CollectedMentions from '../components/shared/CollectedMentions'
import { groupBy, uniq, orderBy, mapValues, map } from 'lodash'
import '../styles/report.css'

const WrittenAnswersPage = ({ loginData }) => {
    const [{ entryState }, getEntries] = useEntryApi({
        token: loginData.token,
        endpoint: 'written-answers'
    })

    const [shouldShowTables, setShouldShowTables] = useState(false)
    const toggleTables = () => { setShouldShowTables(!shouldShowTables) }

    useEffect(() => {
        const defaultDate = moment().subtract(1, 'days')
        getEntries({ startDate: defaultDate, endDate: defaultDate })
        // eslint-disable-next-line
    }, [])

    const answers = entryState.writtenAnswers
    const groupedAnswers = groupBy(answers, (answer) => answer.msp.id)
    const orderedGroupedAnswers = mapValues(groupedAnswers, (group) =>
      orderBy(group, (answer) => getRefAsNumber(answer), ['asc']) 
    )
    const answersWithMspName = map(orderedGroupedAnswers, (group) => {
        const mspName = group[0].msp.name
        return { mspName, group }
    })
    const sortedGroups = orderBy(answersWithMspName, ['mspName'], ['asc']);

    const longAnswers = sortedGroups.map((item) => item.group).flat();
    const shortAnswers = uniq(longAnswers.map(a => a.msp.name))

    if (!entryState.isFetching) {
        return (
            <PageLayout>
                <h1>Written Answers</h1>
                <DateForm
                    initialStartDate={entryState.startDate}
                    initialEndDate={entryState.endDate}
                    submitDates={getEntries}
                />

                <DateInfo
                    startDate={entryState.startDate}
                    endDate={entryState.endDate}
                    showBBLine={false}
                />

                <hr />

                {(
                    entryState.writtenAnswers &&
                        entryState.writtenAnswers.length
                        ? <TableButton showTables={shouldShowTables} toggleTables={toggleTables} />
                        : ''
                )}

                <article id='report-container'>
                    <CollectedMentions entries={entryState.writtenAnswers} />

                    <ShortWrittenAnswerList msps={shortAnswers} />

                    <WrittenAnswerList
                        answers={longAnswers}
                        showTables={shouldShowTables}
                    />
                </article>
            </PageLayout>
        )
    } else {
        return (
            <PageLayout>
                <Loader />
            </PageLayout>
        )
    }
}

export default WrittenAnswersPage
